

import AddViewUtils from "../add/AddViewUtils";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

export default class UpdateViewUtils extends AddViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
  }

  start() {
    super.start();
  }

  renderHeader() {
    if (this.vehicle().isTrailer()) {
      this.addHeader(new TitleDisplayRow().withTitle("Update Trailer"));
    } else {
      this.addHeader(new TitleDisplayRow().withTitle("Update Vehicle"));
    }
  }

}