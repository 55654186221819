<template>
  <UpdateView :vehicleId="vehicleId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></UpdateView>
</template>

<script>
import UpdateView from './UpdateView.vue';
export default {
  name: 'vehicle-list-update-row',
  components: {
    UpdateView
  },
  props: {
    vehicleId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
