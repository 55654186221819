<template>
  <Dialog 
    :vehicleId="vehicleId" 
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue" 
    :isAdmin="isAdmin"></Dialog>
</template>

<script>
import Dialog from './RemoveConfirmDialogView.vue';
export default {
  name: 'vehicle-list-remove-confirm-dialog-row',
  components: {
    Dialog
  },
  props: {
    vehicleId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
