<template>

  <LabelValueRow v-if="ready" :labelWidth="row.labelWidth()" :labelFontSize="row.fontSize()">
    <template #label v-if="row.showLabel()">{{ row.label() }}</template>
    <template #value>
      <Box>
        <Row>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-good rc-text-centre'" 
            v-if="row.value() === 'None'">
            Good
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-good rc-text-centre'" 
            v-else-if="row.value() === 'Good'">
            <div v-if="row.object() == null">Good</div><div v-else>{{  row.object() }}</div>
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-good rc-text-centre'" 
            v-else-if="row.value() === 'paid'">
            Yes
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-na rc-text-centre'" 
            v-else-if="row.value() === 'pastdue'">
            Past Due
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-major rc-text-centre'" 
            v-else-if="row.value() === 'notpaid1day'">
            Due Now
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-major rc-text-centre'" 
            v-else-if="row.value() === 'notpaid1week'">
            Due This Week
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-text-centre'" 
            v-else-if="row.value() === 'notpaid'">
            No
          </div>

          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-minor rc-text-centre'" 
            v-else-if="row.value() === 'Minor'">
            Minor
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-major rc-text-centre'" 
            v-else-if="row.value() === 'Major'">
            Major
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-major rc-text-centre'" 
            v-else-if="row.value() === 'Missing'">
            Missing
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-na rc-text-centre'" 
            v-else-if="row.value() === 'Expired'">
            {{  row.object() }}
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-major rc-text-centre'" 
            v-else-if="row.value() === 'ExpireNext'">
            {{  row.object() }}
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-minor rc-text-centre'" 
            v-else-if="row.value() === 'ExpireSoon'">
            {{  row.object() }}
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-minor rc-text-centre'" 
            v-else-if="row.value() === 'Incomplete'">
            Incomplete
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-inprogress rc-text-centre'" 
            v-else-if="row.value() === 'In Progress'">
            In Progress
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-na rc-text-centre'" 
            v-else-if="row.value() === 'CompleteOff'">
            Complete
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-good rc-text-centre'" 
            v-else-if="row.value() === 'Complete'">
            Complete
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-good rc-text-centre'" 
            v-else-if="row.value() === 'active'">
            Active
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-na rc-text-centre'" 
            v-else-if="row.value() === 'inactive'">
            Inactive
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-inprogress rc-text-centre'" 
            v-else-if="row.value() === 'invited'">
            Invited
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-minor rc-text-centre'" 
            v-else-if="row.value() === 'notinvited'">
            Waiting to be Invited
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-input-na rc-text-centre'" 
            v-else-if="row.value() === 'deleted'">
            Inactive
          </div>
          <div :class="'rc-box-col-' + row.valueWidth() + ' rc-text-centre'" v-else>
            {{  row.value() }}
          </div>
        </Row>
        <Row v-if="row.hint()">
          <Column :width="15">
            <div class="rc-text-xsmall" >{{ row.hint() }}</div>
          </Column>
        </Row>
      </Box>
    </template>
  </LabelValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';

import StatusDisplayRow from "./StatusDisplayRow.js";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

import MC from "@/domain/session/MC.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
//import Inspection from '@/domain/model/inspection/Inspection.js';

export default {
  name: 'status-display-cell',
  components: {
    LabelValueRow,
    Box,Row, Column,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: {},
  },
  data() {
    return {
      ready: false,
      rowData: new StatusDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>