
import ListViewUtils from "./ListViewUtils";

// import Utils from '@/views/portal/inspections/Utils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import VehicleMap from '@/domain/model/vehicle/VehicleMap.js';
// import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
// import Employee from "@/domain/model/employee/Employee.js";
import VehicleListFilter from "@/domain/model/vehicle/VehicleListFilter.js";


export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._total = 0;
    this._error = null;
    this._vehicles = [];
    this._pages = [];
    this._strings = StringUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadVehicles() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Vehicles");
    
    var filter = new VehicleListFilter();
    filter
      .withType(query["type"] ? query["type"] : "all")
      .withState(query["state"] ? query["state"] : "active")
      .withPagination(this.pageSize(query), query["page"] ? (query["page"] - 1) : 0)
      .done();

    let search = query["search"] ? query["search"] : "";
    if (StringUtils.isNotEmpty(search)) {
      filter
        .withSearchText(search)
        .done();
    }

    filter
      .withCompany(this._companyId)
      .done();
    
    var event = this.domain()
        .events()
        .vehicles().list(filter, true);
        
    var context = {};
    context["model"] = this;

    event.send(this.loadVehiclesListener, context);
  }

  loadVehiclesListener(responseEvent, context) {
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(VehicleMap.MODEL_NAME);
      var map = new VehicleMap(model.domain(), payloadMap);
      model._vehicles = map.sortByName();
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDetailsIfRequired();
  }

  vehicles() {
    return this._vehicles;
  }

  user() {
    this._userId = ContentUtils.baseDecode(this.panel().$route.query.userId);
    var user = this.domain().users().findById(this._userId);
    return user;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}