
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import StringUtils from '@/utils/StringUtils.js';

export default class BoxDisplayRow extends RcTableViewRow {

  static Id = "BoxDisplayRow";
  static Border = "border";
  static Height = "height";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, BoxDisplayRow.Id);
    this.withChildren([]);
  }

  withBorder() {
    this.withBorderStyleThin();
    return this;
  }
  
  border() {
    return this.get(BoxDisplayRow.Border);
  }
  
  withHeight(height) {
    this.put(BoxDisplayRow.Height, height);
    return this;
  }

  height() {
    return this.get(BoxDisplayRow.Height);
  }

  hasHeight() {
    return StringUtils.isNotEmpty(this.height());
  }
}