import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
//import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import VehicleImageDisplayRow from "@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderVehicles();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderVehicles() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow().withFixedPosition(true)
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Picture"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Plate"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Safety"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Extra"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;

    var list = this.model().vehicles();
    for (var index = 0; index < list.length; index++) {
      var vehicle = list[index];
      
      var pictureBox = new BoxDisplayRow().withWidth("2");
      pictureBox
        .withChild(new VehicleImageDisplayRow().withValue(vehicle.data))
        ;

      if (!vehicle.isStateActive()) {
        pictureBox
          .withChild(new StatusDisplayRow()
            .withShowLabel(false)
            .withValue(StringUtils.toString(vehicle.state(), "Removed"))
          )
        ;
      }
      var detailsBox = new BoxDisplayRow().withWidth("3");
      detailsBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Name").withValue(vehicle.name()))
        .withChild(new StatusDisplayRow()
          .withLabelWidth("7")
          .withValueWidth("15")
          .withLabel(this.MC.Inspection.Details.StatusLabel.value())
          .withValue(StringUtils.toString(vehicle.defectType(), "Good"))
        )
        ;
      
      if (vehicle.isTrailer()) {
        if (vehicle.hasOdometerSet()) {
          detailsBox
            .withChild(new LabelValueDisplayRow().withName("Hubometer").withLabelWidth("7").withValue(vehicle.odometer() + " " + vehicle.odometerType()))
            ;
        } else {
          detailsBox
            .withChild(new SpacerDisplayRow());
        }
      } else {
        detailsBox
          .withChild(new LabelValueDisplayRow().withName("Odometer").withLabelWidth("7").withValue(vehicle.odometer() + " " + vehicle.odometerType()))
          ;
      }
      detailsBox
        .withChild(new LabelValueDisplayRow().withName("Attributes").withLabelWidth("7").withValue(vehicle.summary()))
        ;

      var plateBox = new BoxDisplayRow().withWidth("3");
      plateBox
        .withChild(new LabelValueDisplayRow().withName("Plate").withLabelWidth("7").withValue(vehicle.plate()))
        ;
      var plateExpiryDate = vehicle.plateExpiryDateV2();
      var plateExiryDisplay = "Missing";
      if (vehicle.isPlateExpiryDateValid()) {
        plateExiryDisplay = plateExpiryDate.displayAs_YYYY_MM_DD();
        if (vehicle.hasPlateExpired()) {
          plateBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Expiry Date").withValue("Expired").withObject(plateExiryDisplay).withHint("Expired"))
          ;
        } else if (vehicle.willPlateExpireInWeeks(4)) {
          plateBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Expiry Date").withValue("ExpireSoon").withObject(plateExiryDisplay).withHint("Expiring Soon"))
          ;
        } else if (vehicle.willPlateExpireInWeeks(1)) {
          plateBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Expiry Date").withValue("ExpireNext").withObject(plateExiryDisplay).withHint("Expiring this week"))
          ;
        } else {
          plateBox
            .withChild(new LabelValueDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Expiry Date").withValue(plateExiryDisplay))
          ;
        }
      } else {
        plateBox
          .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Expiry Date").withValue("ExpireSoon").withObject(plateExiryDisplay))
        ;
      }

      plateBox
        .withChild(new LabelValueDisplayRow().withName("Jurisdiction").withLabelWidth("7").withValue(vehicle.jurisdiction()));

      var safetyBox = new BoxDisplayRow().withWidth("3");
      safetyBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Number").withValue(vehicle.safetyNumber()))
        ;
      if (vehicle.isLastSafetyDateValid()) {
        var safetyDateDisplay = vehicle.annualDateV2().displayAs_YYYY_MM_DD();
        if (vehicle.hasLastSafetyDateExpired()) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Safety Date").withValue("Expired").withObject(safetyDateDisplay).withHint("Expired"))
          ;
        } else if (vehicle.willLastSafetyDateExpireInWeeks(4)) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Safety Date").withValue("ExpireSoon").withObject(safetyDateDisplay).withHint("Expiring Soon"))
          ;
        } else if (vehicle.willLastSafetyDateExpireInWeeks(1)) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Safety Date").withValue("ExpireNext").withObject(safetyDateDisplay).withHint("Expiring this week!"))
          ;
        } else {
          safetyBox
            .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Safety Date").withValue(vehicle.annualDateV2().displayAs_YYYY_MM_DD()))
          ;
        }
      } else {
        safetyBox
          .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Safety Date").withValue("ExpireSoon").withObject("Missing"))
        ;
      }
      
      var extraBox = new BoxDisplayRow().withWidth("4");
      extraBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Type").withValue(this.categoryName(vehicle)));
      if (vehicle.hasWeightSet()) {
        extraBox
          .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("RGW").withValue(vehicle.weight()))
          ;
      } else {
        extraBox
          .withChild(new StatusDisplayRow()
            .withLabelWidth("7")
            .withValueWidth("15")
            .withLabel("RGW")
            .withValue("ExpireSoon")
            .withObject("Missing")
        );
      }
      extraBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Year").withValue(vehicle.year()))
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Make / Model").withValue(this.makeAndModel(vehicle)))
        ;
      var actionsRow = new RowDisplayRow();
      actionsRow
        .withChild(new SpacerDisplayRow().withWidth("9"))
        // .withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(vehicle.id()).withField("Details"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Inspections").withId(vehicle.id()).withField("Inspections"))
        ;

      if (vehicle.isStateActive()) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Remove").withId(vehicle.id()).withField("Remove"))
          ;
      } else {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Restore").withId(vehicle.id()).withField("Restore"))
          ;
      }

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Update").withId(vehicle.id()).withField("Update"))
        ;
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      const body = new BoxDisplayRow()
            .withBorder(true)
            .withEven(even);

      body.withChild(
              new RowDisplayRow()
                .withBorder(false)
                  .withChild(pictureBox)
                  .withChild(detailsBox)
                  .withChild(plateBox)
                  .withChild(safetyBox)
                  .withChild(extraBox)
                );
      body.withChild(
              new RowDisplayRow()
                .withBorder(false)
                .withChild(new SpacerDisplayRow().withWidth("2"))
                .withChild(new LabelValueDisplayRow()
                  .withWidth("10").withName("Schedule")
                  .withLabelWidth("2")
                  .withValue(vehicle.schedule().find().name())
                )
            );
      if (vehicle.checklists().isNotEmpty()) {
        body.withChild(
              new RowDisplayRow()
                .withBorder(false)
                .withChild(new SpacerDisplayRow().withWidth("2"))
                .withChild(new LabelValueDisplayRow()
                  .withWidth("10").withName("Checklist")
                  .withLabelWidth("2")
                  .withValue(vehicle.checklists().first().find().name())
                )
            );
      }

      body.withChild(buttonsBox);

      boxContent
        .withBorder(false)
        .withChild(body);

      even = !even;
    }

    

    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    var user = this.model().user();
    var userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    if (userId) {
      //
    }
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("10"))
          .withChild(new ButtonDisplayRow().withWidth("3").withName("Add Vehicles or Trailer").withField("Add").withBackgroundPrimaryAction())
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("State").withValueWidth("15"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStates").withField("State").withDefaultValue("all").withSelected(query["state"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("goodStates").withField("State").withDefaultValue("active").withSelected(query["state"] === "active"  || !query["state"]))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Removed").withKey("minorStates").withField("State").withDefaultValue("inactive").withSelected(query["state"] === "inactive"))
              .withChild(new SpacerDisplayRow().withWidth("10"))
            )
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Type"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allTime").withField("Type").withDefaultValue("all").withSelected(query["type"] === "all" || !query["type"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Vehicles").withKey("vehicle").withField("Type").withDefaultValue("vehicle").withSelected(query["type"] === "vehicle"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Trailers").withKey("trailer").withField("Type").withDefaultValue("trailer").withSelected(query["type"] === "trailer"))
              .withChild(new SpacerDisplayRow().withWidth("10"))
            )
            .withChild(new RowDisplayRow().withBorder(false)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
              .withChild(new StringUpdateRow().withWidth("5")
                  .withShowLabel(false).withField("Search")
                  .withKey("Search").withName("Search")
                  .withDefaultValue(query["search"] ? query["search"] : ""))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
              .withChild(new SpacerDisplayRow().withWidth("7"))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Timesheets for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

  makeAndModel(vehicle) {
    var value = "";
    var makeSet = false;
    if (StringUtils.isNotEmpty(vehicle.make())) {
      value = vehicle.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(vehicle.model())) {
      if (makeSet) {
        value = value + " / " + vehicle.model();
      } else {
        value = "<Not Set>" + "/ " + vehicle.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + "<Not Set>"
      }
    }
    return value;
  }
  
  categoryName(vehicle) {
    var category = vehicle.category().find();
    var list = ContentUtils.CATEGORIES();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      if (item['@rid'] == category.id()) {
        return item['name'];
      }
    }
    return category.name();
  }
}