<template>

  <Row>
    <Column :width="15" v-if="ready">
      <Box :borderStyle="row.borderStyle()" :backgroundColour="row.backgroundColour()" :style="heightStyle()">

        <Row v-for="(child, childIndex) in children" :key="childIndex">

          <ChildCellFactory :rowData="child.data" :showMissingRequirements="showMissingRequirements"
            v-on:withValue="withValue" v-on:withButtonPressed="withButtonPressed" />

        </Row>

      </Box>
    </Column>
  </Row>


</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";
import StringUtils from '@/utils/StringUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import BoxDisplayRow from "./BoxDisplayRow.js";

export default {
  name: 'box-display-cell',
  components: {
    Box, Row, Column,
    ChildCellFactory: () => import("@/portals/shared/cell/CellFactory.vue")
  },
  props: {
    css: { type: String, default: "" },
    showBorder: { type: Boolean, default: false },
    id: { type: String, default: "id" },
    children: { type: Array, default: () => [] },
    showMissingRequirements: { type: Boolean, default: false },
    data: { type: Object, default: () => { } },
  },
  data() {
    return {
      MC: new MC(),
      ready: false,
      row: new BoxDisplayRow(),
    };
  },
  watch: {
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
  },
  methods: {
    heightStyle: function() {
      if (this.row.hasHeight()) {
        return StringUtils.format("overflow-y: scroll; height:{0};", this.row.height());
      }
      return "";
    },

    withValue: function (kvp) {
      this.$emit('withValue', kvp);
    },
    withButtonPressed: function (buttonData) {
      this.$emit('withButtonPressed', buttonData);
    }
  },
}
</script>