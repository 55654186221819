<template>
  <Row v-if="ready">
    <a v-if="rowData.isImageTransparent()">
      <img src="@/assets/2022/800x800transparent.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageHosMissing()">
      <img src="@/assets/2022/HOS-Missing3.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageHosOffDutyDay()">
      <img src="@/assets/2022/HOS-OffDuty3.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageHosCompleteDay()">
      <img src="@/assets/2022/HOS-Complete2.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageHosInProgressDay()">
      <img src="@/assets/2022/HOS-Incomplete2.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteGood()">
      <img src="@/assets/2022/InspectionCompleteGood.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteMinor()">
      <img src="@/assets/2022/InspectionCompleteMinor.png" width="24px" />
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteMajor()">
      <img src="@/assets/2022/InspectionCompleteMajor2.png" width="24px" />
    </a>
    <img v-else width="25%" src="@/assets/ReadyChekIcon.png" />
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";
import ImageDisplayRow from "./ImageDisplayRow.js";
import Row from '@/portals/shared/library/box/Row.vue';
export default {
  name: 'image-display-cell',
  components: {
    Row,
  },
  props: {
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ImageDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>